const primary = '#6366F1'

export const theme = {
  token: {
    colorPrimary: primary,
    colorLink: primary,
    colorLinkActive: primary,
    colorLinkHover: primary,
  },
}
