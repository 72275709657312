import React, { FC, ReactNode } from 'react'

import { XMarkIcon } from '@heroicons/react/24/solid'
import { Box, Modal as MuiModal, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

import Button from 'src/components/Library/Button/Button'

interface Props {
  onClose?: (event: unknown, reason: string) => void
  open: boolean
  title?: string | ReactNode
  children?: ReactNode
  className?: string
  childClassName?: string
  headerClassName?: string
  dialogClassName?: string
  closeButtonVisible?: boolean
  cancelButtonVisible?: boolean
  disablePortal?: boolean
  loading?: boolean
  confirmDisabled?: boolean
  confirmTooltip?: string
  backDropClickDisable?: boolean
  confirmText?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
  footerVisible?: boolean
  keepMounted?: boolean
}

const Modal: FC<Props> = ({
  onClose = () => {},
  open,
  title = '',
  children,
  dialogClassName,
  closeButtonVisible = true,
  cancelButtonVisible = true,
  disablePortal = true,
  className,
  childClassName = 'mt-3',
  headerClassName = '',
  backDropClickDisable = false,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onConfirm = () => {},
  onCancel = () => {},
  footerVisible = false,
  loading = false,
  confirmDisabled = false,
  confirmTooltip = '',
  keepMounted = false,
}) => {
  return (
    <MuiModal
      keepMounted={keepMounted}
      className={`${
        className || ''
      } md:w-5/8 mx-auto mt-4 flex min-h-screen w-3/4 items-center justify-center overflow-auto text-center sm:block sm:p-0 lg:w-1/2`}
      open={open}
      sx={{
        '.MuiModal-root': {
          zIndex: '100!important',
        },
      }}
      onClose={(event, reason) => {
        if (backDropClickDisable && reason === 'backdropClick') {
          return
        } else {
          onClose && onClose(event, reason)
        }
      }}
      disablePortal={disablePortal}
    >
      <Box
        className={`${dialogClassName} relative top-auto inline-block max-h-[calc(100vh-32px)] w-full overflow-auto rounded-lg bg-white text-left shadow-xl focus-visible:outline-none`}
      >
        {closeButtonVisible && (
          <div
            className={`flex items-center justify-between ${
              title && `border-b border-gray-300 p-4 ${headerClassName}`
            }`}
            data-testid="modal-close-button"
            data-intercom-target="modal-close-button"
          >
            <div className="text-lg font-medium text-gray-900">{title}</div>
            <div className="flex justify-end">
              <IconButton
                aria-label="delete"
                onClick={(event) => onClose(event, 'CloseClick')}
                size="small"
              >
                <XMarkIcon className={'h-5 w-5 text-gray-600'} />
              </IconButton>
            </div>
          </div>
        )}
        <div className={`${childClassName}`}>{children}</div>
        {footerVisible && (
          <div className="flex w-full flex-row justify-end border border-t-gray-300 bg-gray-100 p-4">
            <Stack direction={'row'} spacing={2}>
              {cancelButtonVisible && (
                <Button
                  className="min-w-[0]"
                  fullWidth={false}
                  variant={'text'}
                  onClick={() => {
                    onCancel()
                  }}
                >
                  {cancelText}
                </Button>
              )}
              <Tooltip title={confirmTooltip}>
                {/* Using a fragment so we don't get errors in the console:
                https://github.com/mui/material-ui/issues/31261 */}
                <>
                  <Button
                    disabled={confirmDisabled}
                    className="min-w-[0]"
                    fullWidth={false}
                    onClick={() => {
                      onConfirm()
                    }}
                    loading={loading}
                    buttonDataTestId={'confirm-button'}
                  >
                    {confirmText}
                  </Button>
                </>
              </Tooltip>
            </Stack>
          </div>
        )}
      </Box>
    </MuiModal>
  )
}

export default Modal
