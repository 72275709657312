// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage
//

import { FC } from 'react'

import { captureEvent, setUser } from '@sentry/browser'

import { PrivateSet, Route, Router, Set } from '@redwoodjs/router'

// ⚠️ https://docs.redwoodjs.com/docs/router/#code-splitting
// See also: https://docs.redwoodjs.com/docs/prerender/#flash-after-page-load
// Do not specifically import Pages here, otherwise they are not  dynamically loaded i.e. using code splitting
import MainLayout from 'src/layouts/MainLayout/MainLayout'
import StandardLayout from 'src/layouts/StandardLayout'
import StandardNoSidebarLayout from 'src/layouts/StandardNoSidebarLayout'
import * as Providers from 'src/Providers'
import { LocalStorageKeys } from 'src/types/enums'

const Routes: FC = () => {
  const auth = Providers.useAuth && Providers['useAuth']()

  if (auth?.currentUser?.userData) {
    const { currentUser, client } = auth

    // Set Sentry user context as soon as possible
    setUser({
      id: currentUser.userData.id.toString(),
      email: currentUser.userData.email,
      name: currentUser.userData.name,
    })

    const mfaMandatory = currentUser.parentData?.mfaSetting === 'MANDATORY' && !currentUser.isClientAlias
    const isSSOUser = currentUser.userData.cognitoUserStatus === 'EXTERNAL_PROVIDER'
    // TODO: Once we've established the user's login method,
    // We should cache the result, so we don't have to make this call again.
    // IMPORTANT! Ensure to clear the cache when the user logs out.
    if (mfaMandatory && !isSSOUser) {
      client.currentUser().then((user) => {
        user.getUserData((err, data) => {
          if (err) {
            if (err.message === 'Access Token does not have required scopes') {
              // sso user, no need to force MFA
              return
            } else {
              captureEvent({
                message: 'Routes: Cognito:getUserData: Error getting user data',
                extra: { error: err },
                level: 'error',
              })
            }
          }

          const defaultMFANotSet = data.PreferredMfaSetting !== 'SOFTWARE_TOKEN_MFA'
          const notLoginPath = !window.location.href.includes('/app/login')
          const notLogoutPath = !window.location.href.includes('/app/logout')
          // If the user is already logged in (not on the login or logout pages),
          // force the user to setup MFA
          if (defaultMFANotSet && notLoginPath && notLogoutPath) {
            window.location.href = '/app/login?mfa=mandatory'
          }
        })
      })
    }

    // HACK: Not using useLocalStorage so we don't get a re-render
    localStorage.setItem(LocalStorageKeys.USER_CLIENT_ID, currentUser.membershipData.clientId.toString())
  }

  return (
    <Router useAuth={Providers.useAuth}>
      <Route path="/workflows-form" page={WorkflowsFormPage} name="workflowsForm" />
      <Route path="/workflows-grid" page={WorkflowsFormPage} name="workflowsGrid" />
      <Route path="/app/baselink/form/{formId}" page={BaseLinkPage} name="baseLinkToForm" />
      <Route path="/app/baselink/public/grid/{gridId}" page={BaseLinkPage} name="baseLinkToGrid" />
      <Set wrap={MainLayout}>
        <Route path="/end-user-terms-and-conditions" page={EndUserTermsAndConditionsPage} name="endUserTermsAndConditions" />
        <Route path="/acceptable-use-policy" page={AcceptableUsePolicyPage} name="acceptableUsePolicy" />
        <Route path="/privacy-policy" page={PrivacyPolicyPage} name="privacyPolicy" />
        <Route path="/app/password-reset-confirmation" page={PasswordResetConfirmationPage} name="passwordResetConfirmation" />
        <Route path="/app/password-reset-complete" page={PasswordResetCompletePage} name="passwordResetComplete" />
        <Route path="/app/password-reset-request" page={PasswordResetRequestPage} name="passwordResetRequest" />
        <Route path="/app/password-reset-token" page={PasswordResetTokenPage} name="passwordResetToken" />
        <Route path="/app/login" page={LoginPage} name="login" />
        <Route path="/app/logout" page={LogoutPage} name="logout" />
        <Route notfound page={NotFoundPage} />
        <PrivateSet unauthenticated="login">
          <Route path="/app/end-user-terms-and-conditions-prompt" page={EndUserTermsAndConditionsPromptPage} name="endUserTermsAndConditionsPrompt" />
        </PrivateSet>
        <PrivateSet wrap={StandardLayout} unauthenticated="login">
          <Route path="/app" page={HomePage} name="homeApp" />
          <Route path="/" page={HomePage} name="home" />
          <Route path="/app/ancil" page={AncilPage} name="ancil" />
          <Route path="/app/people/org-chart" page={InformerOrgChartPage} name="informerOrgChart" />
          <Route path="/app/people/org-chart/{id:Int}" page={InformerOrgChartPage} name="informerOrgChartPageId" />
          <Route path="/app/propertyGenie" page={PropertyGeniePage} name="propertyGenie" />
          <Route path="/app/tools/automations" page={AutomationPage} name="automations" />
          <Route path="/app/embed/{sectionId:Int}/{cardId:Int}" page={EmbedPage} name="embed" />
          <Route path="/app/maps" page={ReactMapPage} name="reactMap" />
          <Route path="/app/maps/{id:Int}" page={ReactMapPage} name="reactMapById" />
          <Route path="/app/learn/categories" page={LearnerLearnerCategoriesPage} name="learnerCategories" />
          <Route path="/app/learn/categories/{id:Int}" page={LearnerLearnerCategoriesPage} name="learnerCategoryWithId" />
          <Route path="/app/learn/course/{id:Int}" page={LearnerLearnerCoursePage} name="learnerCourse" />
          <Route path="/app/learn/course/{id:Int}/chapter/{activityId:Int}" page={LearnerLearnerCoursePage} name="learnerCourseActivity" />
          <Route path="/app/learn/course/{id:Int}/chapter/{activityId:Int}/lesson/{taskId:Int}" page={LearnerLearnerCoursePage} name="learnerCourseTask" />
          <Route path="/app/knowledge" page={KnowledgePage} name="knowledge" />
          <Route path="/app/knowledge/{id:Int}" page={KnowledgePage} name="knowledgeWithId" />
          <Route path="/app/knowledge/edit/{id:Int}" page={KnowledgePage} name="knowledgeEditWithId" />
          <Route path="/app/sentiment-analysis" page={SentimentAnalysisPage} name="sentimentAnalysis" />
          <Route path="/app/insights/nps" page={NpsInsightsPage} name="npsInsights" />
          <Route path="/app/insights/nps/{measurementId:Int}" page={NpsInsightsPage} name="npsMeasurementInsight" />
          <Route path="/app/people/directory" page={InformerDirectoryPage} name="informerDirectory" />
          <Route path="/app/people/directory/{membershipId:Int}" page={InformerDirectoryPage} name="informerDirectoryMember" />
          <Route path="/app/goals/overview" page={ImproverGoalsPage} name="improverGoals" />
          <Route path="/app/goals/overview/{goalId:Int}" page={ImproverGoalsPage} name="improverGoalsEdit" />
          <Route path="/app/goals/overview/templates" page={ImproverGoalsPage} name="improverGoalsTemplates" />
          <Route path="/app/goals/overview/templates/{templateId:Int}" page={ImproverGoalsPage} name="improverGoalsTemplate" />
          <Route path="/app/goals/overview/templates/{templateId:Int}/edit" page={ImproverGoalsPage} name="improverGoalsTemplateEdit" />
          <Route path="/app/unsupported" page={UnsupportedPage} name="unsupported" />
          <Route path="/app/azureAppAuth" page={AzureAppAuthPage} name="azureAppAuth" />
          <Route path="/app/propertyMeAppAuth" page={PropertyMeAppAuthPage} name="propertyMeAppAuth" />
          <Route path="/app/changelog" page={ChangelogPage} name="changelog" />
          <Route path="/app/status" page={StatusPage} name="status" />
          <Route path="/app/hubdash" page={HubDashPage} name="hubDash" />
          <Route path="/app/hubdash/{layoutId:Int}" page={HubDashPage} name="hubDashWithId" />
          <Route path="/app/bases" page={HubsrowPage} name="bases" />
          <Route path="/app/baselink" page={BaseLinkPage} name="baseLinkDefault" />
          <Route path="/app/baselink/database/{baseId:Int}" page={BaseLinkPage} name="baseLinkToBase" />
          <Route path="/app/baselink/database/{baseId:Int}/table/{tableId:Int}" page={BaseLinkPage} name="baseLinkToTable" />
          <Route path="/app/baselink/database/{baseId:Int}/table/{tableId:Int}/{viewId:Int}" page={BaseLinkPage} name="baseLinkToView" />
          <Route path="/app/baselink/database/{baseId:Int}/table/{tableId:Int}/{viewId:Int}/row/{rowId:Int}" page={BaseLinkPage} name="baseLinkToRecord" />
          <Route path="/app/baselink/database/{baseId:Int}/table/{tableId:Int}/row/{rowId:Int}" page={BaseLinkPage} name="baseLinkToRecordWithoutView" />
          <Route path="/notification/{workspaceId:Int}/{notificationId:Int}" page={BaseLinkPage} name="baseLinkToNotification" />
          <PrivateSet unauthenticated="home" roles={['OWNER', 'ADMIN', 'EDITOR']}>
            <PrivateSet unauthenticated="home" roles={['OWNER', 'ADMIN']}>
              <Route path="/app/settings/integrations/new" page={CustomerIntegrationsNewPage} name="customerIntegrationsNew" />
              <Route path="/app/settings/integrations" page={CustomerIntegrationsPage} name="customerIntegrations" />
              <Route path="/app/settings/client/billing" page={SettingsClientBillingPage} name="settingsClientBilling" />
              <Route path="/app/settings/client/billing/{status:String}" page={ClientBillingConfirmationPage} name="clientBillingConfirmation" />
              <Route path="/app/settings/client/billing/plan/upgrade" page={ClientSubscriptionUpgradePage} name="clientSubscriptionUpgrade" />
              <Route path="/app/settings/client/billing/addon/upgrade/{addonId:String}" page={ClientSubscriptionUpgradePage} name="clientAddonUpgrade" />
              <Route path="/app/settings/client/billing/addon/purchase" page={ClientSubscriptionUpgradePage} name="clientAddonPurchase" />
              <Route path="/app/settings/client/configuration" page={SettingsClientConfigurationPage} name="settingsClientConfiguration" />
              <Route path="/app/settings/user-management/users" page={SettingsUsersPage} name="settingsUsers" />
              <Route path="/app/settings/user-management/groups" page={SettingsGroupsPage} name="settingsGroups" />
              <Route path="/app/settings-sentiment-analysis" page={SettingsSentimentAnalysisPage} name="settingsSentimentAnalysis" />
              <Route path="/app/settings-sentiment-analysis/{tab:String}" page={SettingsSentimentAnalysisPage} name="settingsSentimentAnalysisTab" />
              <Route path="/app/settings/formula-builder" page={FormulaBuilderPage} name="formulaBuilder" />
              <Route path="/app/settings/audit-logs" page={AuditLogPage} name="auditLogs" />
              <Route path="/app/document-storage" page={DocumentStoragePage} name="documentStorageDocuments" />
              <Route path="/app/document-storage/{documentId:Int}" page={DocumentStoragePage} name="documentStorageDocument" />
              <Route path="/app/document-storage/{documentId:Int}/view" page={DocumentStoragePage} name="documentStorageDocumentView" />
            </PrivateSet>

            <Route path="/app/settings/announcements" page={SettingsAnnouncementsPage} name="settingsAnnouncements" />
            <Route path="/app/settings/client/home-screen" page={SettingsClientToolsPage} name="settingsClientTools" />
            <Route path="/app/settings/learner/courses/{id:Int}/edit" page={SettingsLearnerLearnerCourseEditLearnerCoursePage} name="editLearnerCourse" />
            <Route path="/app/settings/learner/categories" page={SettingsLearnerLearnerCategoryLearnerCategoriesPage} name="settingsLearnerCategories" />
            <Route path="/app/learn/course-reports" page={LearnerLearnerCourseReportsPage} name="learnerCourseReports" />
            <Route path="/app/learn/course/{id:Int}/summary" page={LearnerLearnerCourseSummaryPage} name="learnerCourseSummary" />
            <Route path="/app/settings/nps" page={SettingsNPSPage} name="settingsNps" />
          </PrivateSet>
          <PrivateSet unauthenticated="home" roles={['STAFFLINK', 'SUPERADMIN']}>
            <Route path="/app/settings/importers" page={HubsImportersPage} name="hubsImporters" />
          </PrivateSet>
          <PrivateSet unauthenticated="home" roles={['SUPERADMIN']}>
            <Route path="/devtools" page={DevToolsPage} name="devTools" />
            <Route path="/superadmin/integrations" page={SuperAdminIntegrationsPage} name="integrations" />
            <Route path="/superadmin/asset-library" page={AssetLibraryPage} name="assetLibrary" />
            <Route path="/superadmin/default-home-links" page={DefaultHomeLinksPage} name="defaultHomeLinks" />
            <Route path="/superadmin/clients/new" page={SuperAdminClientNewClientPage} name="newClient" />
            <Route path="/superadmin/clients/{id:Int}/edit" page={SuperAdminClientEditClientPage} name="editClient" />
            <Route path="/superadmin/clients" page={SuperAdminClientClientsPage} name="clients" />
            <Route path="/superadmin/features" page={FeatureManagementPage} name="featureManagement" />
            <Route path="/superadmin/feature-dashboard" page={FeatureDashboardPage} name="featureDashboard" />
            <Route path="/superadmin/client-features" page={ClientFeaturesPage} name="clientFeatures" />
            <Route path="/superadmin/queuer-dashboard" page={QueuerDashboardPage} name="queuerDashboard" />
            <Route path="/superadmin/queuer-dashboard/{tabId:Int}" page={QueuerDashboardPage} name="queuerDashboardTab" />
            <Route path="/superadmin/baserow-import" page={BaserowImportPage} name="baserowImport" />
            <Route path="/superadmin/baserow-tools" page={BaserowToolsPage} name="baserowTools" />
            <Route path="/superadmin/webhooks" page={WebhooksPage} name="webhooks" />
          </PrivateSet>
        </PrivateSet>
      </Set>
      <Set wrap={StandardNoSidebarLayout}>
        <PrivateSet unauthenticated="home" roles={['OWNER', 'ADMIN']}>
          <Route path="/app/onboarding" page={OnboardingOnboardingPage} name="onboarding" />
          <Route path="/app/onboarding/settings" page={OnboardingOnboardingClientSettingsPage} name="onboardingClientSettings" />
          <Route path="/app/onboarding/members" page={OnboardingOnboardingMembersPage} name="onboardingMembers" />
          <Route path="/app/onboarding/membership-groups" page={OnboardingOnboardingMembershipGroupsPage} name="onboardingMembershipGroups" />
          <Route path="/app/onboarding/home-links" page={OnboardingOnboardingHomeLinksPage} name="onboardingHomeLinks" />
          <Route path="/app/onboarding/integrations" page={OnboardingOnboardingIntegrationsPage} name="onboardingIntegrations" />
        </PrivateSet>
      </Set>
    </Router>
  )
}

export default Routes
