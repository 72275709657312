import React, { useState, useCallback, Fragment, FC, ReactNode } from 'react'

import ConfirmationDialog from './ConfirmationDialog'
import ConfirmContext from './ConfirmContext'
import { ConfirmOptions } from './types'

const DEFAULT_OPTIONS: ConfirmOptions = {
  title: 'Are you sure?',
  description: '',
  content: null,
  confirmationText: 'Ok',
  cancellationText: 'Cancel',
  dialogProps: {},
  confirmationButtonProps: {},
  cancellationButtonProps: {},
  titleProps: {},
  contentProps: {},
  allowClose: true,
  hideCancelButton: false,
}

const buildOptions = (
  defaultOptions: ConfirmOptions,
  options: ConfirmOptions,
) => {
  const dialogProps = {
    ...(defaultOptions.dialogProps || DEFAULT_OPTIONS.dialogProps),
    ...(options.dialogProps || {}),
  }
  const confirmationButtonProps = {
    ...(defaultOptions.confirmationButtonProps ||
      DEFAULT_OPTIONS.confirmationButtonProps),
    ...(options.confirmationButtonProps || {}),
  }
  const cancellationButtonProps = {
    ...(defaultOptions.cancellationButtonProps ||
      DEFAULT_OPTIONS.cancellationButtonProps),
    ...(options.cancellationButtonProps || {}),
  }
  const titleProps = {
    ...(defaultOptions.titleProps || DEFAULT_OPTIONS.titleProps),
    ...(options.titleProps || {}),
  }
  const contentProps = {
    ...(defaultOptions.contentProps || DEFAULT_OPTIONS.contentProps),
    ...(options.contentProps || {}),
  }

  return {
    ...DEFAULT_OPTIONS,
    ...defaultOptions,
    ...options,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
  }
}

interface Props {
  defaultOptions?: ConfirmOptions
  children: ReactNode
}

const ConfirmProvider: FC<Props> = (props) => {
  const { children, defaultOptions = {} } = props
  const [options, setOptions] = useState<ConfirmOptions>({})
  const [resolveReject, setResolveReject] = useState([])
  const [resolve, reject] = resolveReject

  const confirm = useCallback((options: ConfirmOptions = {}) => {
    return new Promise((resolve, reject) => {
      setOptions(options)
      setResolveReject([resolve, reject])
    })
  }, [])

  const handleClose = useCallback(() => {
    setResolveReject([])
  }, [])

  const handleCancel = useCallback(() => {
    if (reject) {
      resolve(false)
      handleClose()
    }
  }, [reject, handleClose, resolve])

  const handleConfirm = useCallback(() => {
    if (resolve) {
      resolve(true)
      handleClose()
    }
  }, [resolve, handleClose])

  return (
    <Fragment>
      <ConfirmContext.Provider value={confirm}>
        {children}
      </ConfirmContext.Provider>
      <ConfirmationDialog
        open={resolveReject.length === 2}
        options={buildOptions(defaultOptions, options)}
        onClose={handleClose}
        onCancel={handleCancel}
        onConfirm={handleConfirm}
      />
    </Fragment>
  )
}

export default ConfirmProvider
